import React from "react";
import Page from "~/templates/Page";

const data = {
  page: {
    metadata: {
      title: "DMI Ecosystem",
      description: {
        internal: {
          content:
            "PT Blink is creating an ecosystem of all stakeholders in a Blink DMI™ property development project.",
        },
      },
      image: null,
    },
    sections: [
      {
        __typename: "ContentfulTextAndChecklistSection",
        heading: "Blink DMI® Ecosystem",
        upperContent: {
          raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"PT Blink is creating an ecosystem of all stakeholders in a Blink DMI","nodeType":"text"},{"data":{},"marks":[],"value":"®","nodeType":"text"},{"data":{},"marks":[],"value":" property development project. The ecosystem fosters collaboration and data sharing across all aspects of the development cycle, from design, manufacture and integration to certification, insurance and facilities management.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
        },
        checklistItems: [
          "A collaborative ecosystem​",
          "New business opportunities for members​",
          "Speed to market for developers",
          "Better outcomes for property owners​",
        ],
        lowerContent: {
          raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Owners and developers benefit from dramatic time savings and quality assurance which translates to a higher internal rate of return, realising the asset value sooner and maximising long-term value. ​","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"​For Blink DMI","marks":[],"data":{}},{"nodeType":"text","value":"®","marks":[],"data":{}},{"nodeType":"text","value":" partners, the ecosystem serves as a growing pool of opportunity for new business and collaboration. ​","marks":[],"data":{}}]}]}',
        },
        id: "29f9ce2b-79d0-5f5a-b999-42f623377edf",
      },
      {
        __typename: "ContentfulTextAndCardsSection",
        title: "The Ecosystem",
        displayType: "white",
        numberOfColumns: 2,
        headingLogo: null,
        summary: null,
        buttonText: null,
        buttonLink: null,
        cards: [
          {
            id: "dce1e67a-ccc9-543d-91b2-de9f9ff31a20",
            title: {
              internal: {
                content: "Owners and Developers",
              },
            },
            image: {
              title: "Property Owners & Developers",
              file: {
                url: "//images.ctfassets.net/thdo0maiis3p/6CLpKfrpQoiSMJQjbaA1EH/4efcd0cf3d646790d342bba82ed8869c/Property_Owners___Developers.svg",
              },
              gatsbyImageData: null,
            },
            content: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Blink your development and increase your IRR up to 50%.","nodeType":"text"}],"nodeType":"paragraph"},{"data":{},"content":[{"data":{},"marks":[],"value":"\\n\\n\\n","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
            ctaLink: "/owners-and-developers",
            ctaText: "Learn more",
          },
          {
            id: "4e95819a-c6ad-5578-975f-acbebb04335a",
            title: {
              internal: {
                content: "Blink DMI® Partners",
              },
            },
            image: {
              title: "DMI Ecosystem Partners",
              file: {
                url: "//images.ctfassets.net/thdo0maiis3p/7KPVUToy1ZvlVvNmB2qUsp/7c4c75a005ea66183910a75454b03f2f/DMI_Ecosystem_Partners.svg",
              },
              gatsbyImageData: null,
            },
            content: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Join our ecosystem to access projects and move up the value chain.","nodeType":"text"}],"nodeType":"paragraph"},{"data":{},"content":[{"data":{},"marks":[],"value":"\\n\\n\\n","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
            ctaLink: "/dmi-partners",
            ctaText: "Learn more",
          },
        ],
        id: "ffba1bb5-41e8-5502-9463-e5aa1cd60102",
      },
      {
        __typename: "ContentfulTextAndCardsSection",
        title: "Get Started",
        displayType: "blue",
        numberOfColumns: 2,
        headingLogo: null,
        summary: {
          raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","content":[{"nodeType":"text","value":"Let’s talk! Contact us with general enquiries or register your specific interest below.","marks":[],"data":{}}],"data":{}}]}',
        },
        buttonText: "Contact Us",
        buttonLink: "/contact/",
        cards: [
          {
            id: "99314b58-f0a4-5082-a598-4a304eb53733",
            title: {
              internal: {
                content: "FREE consultation -\nProperty Owners & Developers",
              },
            },
            image: null,
            content: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Receive a free initial project assessment and understand the likely benefits of using Blink DMI®.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
            ctaLink: "/book-consultation/",
            ctaText: "Book free consult",
          },
          {
            id: "13f6c73d-182e-58b1-83ad-3090fb55944b",
            title: {
              internal: {
                content: "Designers, Manufacturers and Integrators (builders)",
              },
            },
            image: null,
            content: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Join to access projects and move up the value chain.​","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
            ctaLink: "/register/",
            ctaText: "Register to join",
          },
        ],
        id: "e2b5a91c-da91-57d0-b2ac-0967dc096306",
      },
    ],
  },
};

function DmiEcosystem() {
  return <Page data={data} />;
}

export default DmiEcosystem;
